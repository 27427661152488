@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

$font-family: Montserrat;
@import '~vuetify/src/styles/styles.sass';

.v-application {
  font-family: $font-family, sans-serif !important;
  [class*='text-'] {
    font-family: $font-family !important;
  }
  $desktop-factor: 1;

  .title-page {
    font-family: 'Montserrat', 'sans-serif' !important;
    font-weight: 700 !important;
    font-size: 36px !important;
    line-height: 44px !important;
    color: #0F293B !important;
  }

  .title-page-mobile {
    font-family: 'Montserrat', 'sans-serif' !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 29.4px !important;
    letter-spacing: 0.015em !important;
  }

  .title-page-2 {
    font-family: 'Montserrat', 'sans-serif' !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 36.57px !important;
  }

  .text-big-title {
    font-weight: 600;
    font-size: 40px !important;
    line-height: normal;
    letter-spacing: 0;
  }

  .text-high-title {
    font-weight: 600;
    font-size: 36px !important;
    line-height: normal;
    letter-spacing: 0;
  }

  .highTitle {
    font-weight: 600;
    font-size: 36px;
    line-height: 24px;
    letter-spacing: 0;
  }

  .text-medium-title {
    font-weight: 600;
    font-size: 28px !important;
    line-height: normal;
    letter-spacing: 0;
  }

  .title-1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }

  .text-title-2 {
    font-weight: 600;
    font-size: 18px !important;
    line-height: normal;
    letter-spacing: 0;
  }

  .title-2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
  }

  .title-3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }

  .title-4 {
    font-weight: 400;
    font-size: 18px !important;
    line-height: 22px;
    letter-spacing: 0;
  }

  .body-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
  }

  .links-1 {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0;
  }

  .subtext-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0;
  }

  .text-subtext-1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
  }

  .subtext-1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
  }

  .text-subtext-3 {
    font-weight: 400;
    font-size: 10px !important;
    line-height: normal;
  }

  .subtext-3 {
    font-weight: 400;
    font-size: 10px;
  }

  .buttons-text {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .headersBg {
    background-color: #dcdded;
  }

  .full-height {
    height: 100% !important;
  }

  .full-width {
    width: 100% !important;
  }

  .max-w-1512 {
    max-width: 1512px !important;
  }

  .max-w-1364 {
    max-width: 1364px !important;
  }

  .max-w-1200 {
    max-width: 1200px !important;
  }

  .rounded-btn {
    border-radius: 13px !important;
  }

  .modal-shadow-btn {
    box-shadow: -1px 1px 8px 0px #7E72F294 !important;
  }

  .font-weight-semibold {
    font-weight: 600 !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .wrap-icon {
    border-radius: 20px;
    width: 60px;
  }

  .wrap-button {
    border-radius: 15px;
  }

  .v-chip {
    white-space: normal !important;
  }

  .v-chip__content {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .v-list-item__title,
  .v-list-item__subtitle {
    white-space: normal !important;
  }

  .custom-placeholer input::placeholder {
    font-size: 14px;
    color: (var(--v-primary-base)) !important;
    opacity: 1;
  }

  .custom-icon {
    .v-input__slot {
      padding: 0 0 !important;
    }
    .v-icon {
      color: (var(--v-primary-base)) !important;
    }
  }

  .word-wrap-normal {
    word-wrap: normal !important;
  }

  .word-wrap-break {
    word-wrap: break-word !important;
  }

  .work-break {
    word-break: break-word !important;
  }

  // Add other bg color to even/odd lines in datatable
  .tableColors {
    tbody tr:nth-of-type(even) {
      background-color: #f4f6f6;
    }
  }

  .bg-card-header {
    background: linear-gradient(212.1deg, #00D5A4 -53.74%, #00D5A4 -53.72%, #979797 127.27%) !important;
  }

  .bg-chip {
    background: rgba(0, 213, 164, 0.2) !important;
  }

  .shadow-light {
    box-shadow: 0 13px 19px rgba(0, 0, 0, 0.07) !important;
  }

  .shadow-light-2 {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25) !important;
  }

  .shadow-medium {
    box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.25) !important;
  }

  .shadow-strong {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25) !important;
  }

  .drop-shadow-light {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  }

  .text-transform-initial {
    text-transform: initial !important;
  }

  .text-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .text-clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  // grid layouts

  .d-grid {
    display: grid !important;
  }

  .grid-cols-1 {
    grid-template-columns: repeat(1, auto) !important;
  }

  .grid-cols-2 {
    grid-template-columns: repeat(2, auto) !important;
  }

  .grid-cols-3 {
    grid-template-columns: repeat(3, auto) !important;
  }

  .grid-cols-4 {
    grid-template-columns: repeat(4, auto) !important;
  }
}
