
.cookies-banner-container {
  position: fixed;
  max-width: 700px;
  width: 100%;
  right: 20px;
  bottom: 20px;
  border-radius: 21px;
  padding: 0 20px 20px;

  .v-card__text {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
  }

  :deep(.v-card__actions) {
    gap: 20px;

    span.v-btn__content {
      text-transform: initial;
    }
  }

  @media screen and (max-width: 735px) {
    bottom: 0;
    right: 0;
    max-width: 100%;
  }
}
