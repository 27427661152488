
.cookies-card {
  .cookie-title {
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  .cookies-information{
    color: var(--v-grayText-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .cookie-name,
  .cookie-tab {
    color: var(--v-grayText-base);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
  }
}
